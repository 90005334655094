import React from 'react'

const CookieIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="46" width="46" viewBox="0 0 36 36">
      <path
        fill="#DA9F83"
        stroke="#000"
        d="M34.966 17.87c.414 4.649-2.082 8.731-4.831 12.206-2.304 2.554-5.602 3.631-8.901 4.285-3.198 1.089-6.766.739-9.716-.895-1.034-.43-2.248-.559-3.167-1.176-2.879-1.846-4.524-4.927-5.779-8.029-1.627-2.916-1.74-6.483-1.414-9.742.219-1.107.967-2.032 1.388-3.051.729-2.127 1.916-3.963 3.569-5.475.786-.787 1.377-1.823 2.303-2.444.919-.617 2.103-.758 3.137-1.188 1.016-.422 1.968-1.08 3.072-1.299 1.072-.213 2.201.106 3.335.106 2.235-.056 4.482.148 6.575.789 1.034.43 1.781 1.38 2.7 1.997.926.622 1.999 1.04 2.784 1.827 1.603 1.566 2.984 3.411 3.94 5.446.65 2.109.957 4.389 1.005 6.643z"
      />
      <path
        fill="#8A4B38"
        stroke="#000"
        d="M22.638 16.947c1.177-.385 2.444.261 2.827 1.44.384 1.179-.26 2.447-1.441 2.831-1.181.384-3.871.201-4.255-.978-.383-1.181 1.687-2.911 2.869-3.293zm8.57-.672c-.38.452-1.055.512-1.507.131-.452-.381-.511-1.055-.131-1.508.381-.451 1.514-1.057 1.966-.677.453.381.053 1.601-.328 2.054zm-22-7c-.38.452-1.055.512-1.507.131-.452-.381-.511-1.054-.131-1.508.381-.451 1.514-1.057 1.966-.677.453.381.053 1.601-.328 2.054zm12.093-1.894c-.748-.478-.969-1.472-.49-2.22.479-.749 1.473-.968 2.222-.49.749.479 1.872 2.05 1.394 2.798-.48.75-2.377.392-3.126-.088zm-7.926 6.217c-.515-1.066-.064-2.351 1.005-2.864s2.351-.061 2.864 1.008c.513 1.07.682 3.643-.387 4.154-1.071.514-2.971-1.227-3.482-2.298zm1.024 14.099c.602-.874 1.8-1.096 2.672-.492.874.605 1.094 1.801.49 2.675s-2.528 2.147-3.4 1.544c-.875-.604-.366-2.854.238-3.727zm10.013-.406c-.687-.436-.889-1.348-.449-2.034.438-.685 1.349-.886 2.035-.447.686.438 1.715 1.878 1.276 2.562-.438.688-2.176.359-2.862-.081zM5.536 18.42c.762-.853 2.071-.926 2.923-.163.852.764.924 2.071.161 2.922-.763.854-2.992 1.955-3.843 1.191-.854-.763-.005-3.099.759-3.95z"
      />
    </svg>
  )
}

export default CookieIcon
